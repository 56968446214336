.store-item-wrapper{ 
    width: 220px;
    height: 220px;
}

.store-item-wrapper h2{
    text-align: center;
    margin: 0;
    font-size: 24px;
}

.img-wrapper{ 
    text-align: center;
}

.store-img{
    width: 70%;
}