.menu-items {
    position: relative;
    font-size: 14px;
    margin: 0;
    padding: 0;
    /* background-color: aqua; */
}

.drop-item{
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    padding: 5px;
    position: relative;
}

.drop-item:hover{
    color: var(--primary);
}

.circle{
    height: 30px;
    width: 30px;
    border: 1px solid #e2e2e2;
    border-radius: 30px;
    margin-right: 6px;
}
  
.menu-items Link {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
}


.menu-items Link:hover,
.menu-items button:hover {
    color: var(--primary);
}