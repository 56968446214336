.button{
    border: none;
    outline: none;
    height: 56px;
    width: 100%;
    background: var(--primary);
    color: var(--white);
    font-size: 18px;
    align-self: center;
    margin-bottom: 10px;
    border-radius: 20px;
}

