@font-face {
    font-family: outfit-bold;
    src: url("../../assets/fonts/Outfit-Bold.ttf");
}

@font-face {
    font-family: outfit-regular;
    src: url("../../assets/fonts/Outfit-Regular.ttf");
}

@font-face {
    font-family: outfit-light;
    src: url("../../assets/fonts/Outfit-Regular.ttf");
}


.page-content{
    background: #FFF;
    padding: 30px 0px;
}

.heading-1{
    font-size: 24pt;
    color: #000;
    text-align: center;
    font-family: outfit-bold;
    margin-bottom: 20px;
}

.heading-2{
    font-size: 16pt;
    color: #000;
    font-family: outfit-bold;
}

 p,span,li {
    font-size: 12pt;
    color: #000;
    font-family: outfit-regular;
}

/* li{
    list-style-type: disc;
} */

section p {
    margin-bottom: 20;
}

.all-caps{
    text-transform: uppercase;
}

.row-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .row-flex-space {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .column-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .link {
    text-decoration: none;
  }
  
  .pointer {
    cursor: pointer;
    padding: 0;
  }

.app-bar{
    background: #D90429;
    height: 2.5rem;
    width: 100%;
position: relative;
}

.app-bar-container{
    top: 0;
    z-index: 100;
    position: sticky;
}

.bar-wrapper{
    height: 3.5rem;
    background: #FBFBFB 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 6px #0000001A;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

}

.slash{
    font-size: 28px;
    color: #707070;
    margin: 8px;
}

.bar-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background: #00B723 0% 0% no-repeat padding-box;
    border: 1px solid #D8D8D8;
    border-radius: 60px;
    margin-right: 5px;
}

.secure-text{
    font-size: 20px;
    font-weight: normal;
    color: var(--unnamed-color);
}

.ship-text{
    font-size: 16px;
    font-weight: normal;
    color: #D90429;
}

.ship-text:hover{
    cursor: pointer;
}

@media screen and ( max-width: 860px ) {
    .link-text{
        display: none;
    }
}
