.grid-column {
    display: grid;
    grid-template-columns: 70% 30%;
    gap: 15px;
}

.title_filter {
    font-size: 16px;
    color: #000;
}

.side1 {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 15px 0px;
}

.side2 {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 15px 0px;
}

.grid-cart-header {
    display: grid;
    grid-template-columns: 5% 30% 20% 15% 15% 15%;
    gap: 2px;
    border-bottom: 3px solid #D9042940;
}

h2 {
    font-size: 22px;
    font-weight: 500;

}

/* Cart-Item */

.cart-item {
    padding-left: 5px;
}

.cart-item-title1 {
    font-size: 18px;
    font-weight: 500;
    color: #000;
}

.counter1 {
    height: 2rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D8D8D8;
    border-radius: 50px;
    width: 70%;
}

/* Cart Item Mobile */
.grid-cart-mobile {
    border-bottom: 1px solid #707070;
    margin: 15px 0;
}

#cart-mobile {
    display: none;
}

#cart-prices {
    padding: 0px 10px;
    margin: 10px 0;
}

/* Select */
.select_routing {
    outline: none;
    border: none;
    display: none;
    background-color: transparent;
}

.select2 {
    height: 2rem;
    border-radius: 35px;
}


.proceed1 {
    font-size: 36px;
    font-weight: bold;
    color: var(--white);
}

/* Responsive styles */
@media screen and (max-width: 1340px) {
    .title_filter {
        font-size: 16px;
    }
}

@media screen and (max-width: 1250px) {
    .proceed1 {
        font-size: calc(80% + 1vw);
    }
}

@media screen and (max-width: 1000px) {
    .title_filter {
        font-size: calc(16px - .3vw);
    }

    .gray {
        font-size: calc(16px - .3vw);
    }
}

@media screen and (max-width: 1170px) {
    .grid-cart-header h2 {
        font-size: calc(50% + 1vw);
    }

    .side2 h2,
    .side2 h1 {
        font-size: calc(70% + 1vw);
    }
}

@media screen and (max-width: 1130px) {
    .grid-column {
        display: flex;
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 800px) {

    #cart {
        display: none;
    }

    #cart-mobile {
        display: block;
    }

    .grid-cart-header {
        display: none;
    }

    #mobile-count {
        width: 45%;
    }

    .select {
        width: 50%;
    }
}

@media screen and (max-width: 460px) {

    .grid-cart-mobile {
        height: 10.5rem;
    }

    #cart-mobile {
        position: relative;
    }

    #wrap {
        position: relative;
        height: 100%;
    }

    #cart-prices {
        display: inline-block;
        padding: 0;
    }

    #wrap-ship {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        left: 0;
        bottom: 10px;
    }

    .select {
        margin: 0 5px;
        flex: 1;
    }

    #delete-icon {
        position: absolute;
        bottom: 30%;
        right: 0px;
    }
}