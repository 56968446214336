
.navbar{
  width: 100%;
  box-sizing: border-box;
  background: var(--header-color);
  box-shadow: 0px 4px 6px #0000001A;
  transition: all .7s ease-in;
  padding-top: 6px;
}

.logo-txt span{
    font-size: 38px;
    font-weight: bolder;
}

.head-input{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.head-h3{
    width: 65%;
    margin: 0 5px;
}

.head-h3 h3{
    width: 20%;
    padding: 3px;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    text-align: center;
}


.head-icons img{
    height: calc( 1vw * 0.30px );
}

.cart-txt h3{
    margin: 0;
    font-size: calc( 1vw + 0.12px );
    color: var(--black-secondary);
}

.header-icon{
    color: var(--unnamed-color);
}

.icon-btn{
    margin-left: 45px;
}

.header-bottom{
    background: padding-box;
    position: relative;
}

.cat-trigger{ 
    width: 274px;  
    position: relative; 
    height: 3rem; 
    margin: 0;
    padding: 0;
}

.cat-trigger .cat-txt{
    color: var(--white);
    font-weight: 550;
    padding: 5px;
}

.pointer{
    padding: 12px; 
    display: flex;
    position: relative;
    width: 100%
}

.brand-btn{
    background: var(--white);
    border-radius: 50px;
    height: 42px;
    width: 42px;
    margin: 4px 6px;
    box-shadow: 0px 5px 20px #2424240D;
}

.brand-btn:hover > .brand-icon {
    color: var(--white);
}

/* Header Store Styles */

.logo-center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.header-btm{
    position: relative;
    height: fit-content;
    padding: 10px;
}

.store-logo{
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(-30%, -50%);
}

.store-logo h1{
    font: bolder 32px Futura;
    margin: 0;
}

.store-logo span{
    color: var(--primary);
    font: normal 12px Futura;
    margin: 0;
}

.store-grades{
    position: relative;
}

.grades{ 
    margin: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 5px;
    cursor: default;
}

.grades span{
    font: normal 14px Futura;
    color: #656565;
}

.active-grade{
    width: 75px;
    height: 45px;
    background: #FBEDFE 0% 0% no-repeat padding-box;
}

.inactive-grade{
    width: 75px;
    height: 45px;
    background: #F8F8FA 0% 0% no-repeat padding-box;
}


/* Responsive Styles */

@media screen and ( max-width: 1280px ) {
    .head-h3 h3{
        width: 15%;
        padding: 3px;
        font-size: 12px;
        text-align: center;
    }
}

@media screen and ( max-width: 1220px ) {
    .head-h3{
        width: 60%;
        flex-direction: column;
    }

    .head-input{
        width: 100%;
        justify-self: center;
        align-self: center;
    }

    .head-h3 h3{
        display: none;
    }
}

@media screen and ( max-width: 1160px ) {
    
}

@media screen and ( max-width: 1090px ) {
    

}

@media screen and ( max-width: 1050px ) {
    

}

@media screen and ( max-width: 1000px ) {
    .head-input{
        width: 100%;
    }
}

@media screen and ( max-width: 860px ) {
   
}