.favorite-item-wrapper {
    width: 220px;
    padding: 20px;
    height: 310px;
    margin: 10px;
}

.favorite-item-wrapper h3 {
    font-size: 24px;
    margin: 0;
    font-weight: 500;
    text-align: center;
}

.img-fav-container {
    margin: 5px 0;
    height: 65%;
    width: 100%;
    background: #000;
}

.img-fav-container img {
    width: 100%;
    height: 100%;
}