.app-bar{
    background: var(--primary);
    height: 2.5rem;
    width: 100%;
}

.app-bar-container{
    top: 0;
    z-index: 100;
}

@media screen and ( max-width: 860px ) {
    .link-text{
        display: none;
    }
}
