.head-input {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.total-box {
    padding: 10px;
    border: 1px solid lightgrey;
    width: fit-content;
    margin: 20px 40px;
}

.bordered-box {
    border: 1px solid var(--primary);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 0px 10px;
}