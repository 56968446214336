
.terms-services{
    text-align: left;
    font: normal normal normal 20px Poppins;
    letter-spacing: 0px;
    color: var(--black-secondary);
}

.signin-link{
    color: var(--primary);
    font-size: 18px;
    margin: 4px 5px;
}

.signin-link:hover {
    cursor: pointer;
}

.forgot-pass:hover {
    cursor: pointer;
    color: var(--primary);
}