* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.chat {
  /* width: 60%; */
  height: 85vh;
  display: flex;
  align-items: center;
  position: relative;
  align-self: center;
}
.chat__sidebar {
  height: 100%;
  width: 35%;
  background-color: #f9f5eb;
  flex: 0.2;
  padding: 20px;
  border-right: 1px solid #fdfdfd;
  overflow-x: hidden;
  overflow-y: auto;
}
.chat__main {
  height: 100%;
  flex: 0.7;
  padding: 0px;
}
.chat__header {
  margin: 1px 0 10px 0;
}
.chat__users > * {
  margin-bottom: 10px;
  color: black;
  font-size: 17px;
  font-weight: bold;
}
.online__users > * {
  margin-bottom: 10px;
  color: rgb(238, 102, 102);
  font-style: italic;
}
.chat__mainHeader {
  width: 100%;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f9f5eb;
}
.message__container {
  width: 100%;
  height: 85%;
  background-color: #fff;
  padding: 20px;
  overflow-y: scroll;
  overflow-x: hidden;

  overscroll-behavior-y: contain;
  scroll-snap-type: proximity;
}
.message__container > div:last-child {
 scroll-snap-align: end;
}

.message__container > * {
  margin-bottom: 10px;
}
.chat__footer {
  padding: 5px;
  height: 10%;
  background-color: #d2c2d6;
  /* height: 7vh; */
  display: flex;
  flex-direction: row;
}
.form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.message {
  width: 80%;
  height: auto;
  max-height: 8vh;
  border-radius: 10px;
  border: 1px solid #ddd;
  outline: none;
  padding: 10px;
}
.message__recipient {
  background-color: #f5ccc2;
  width: 310px;
  padding: 7px 5px 1px 5px;
  border-radius: 10px;
  font-size: 15px;
}
.message__sender {
  background-color: rgb(194, 243, 194);
  max-width: 310px;
  padding: 7px 5px 1px 5px;
  border-radius: 10px;
  margin-left: auto;
  font-size: 15px;
}
.message__chats > p {
  font-size: 13px;
}
.sender__name {
  text-align: right;
}
.message__status {
  position: fixed;
  bottom: 50px;
  font-size: 13px;
  font-style: italic;
}