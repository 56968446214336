.action-btn{
    width: 34px;
    height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    /* margin: 0 15px; */

}

.action-btn:hover{
    cursor: pointer;
    border: 1px solid var(--white);
    background: var(--primary);
    color: var(--white);
}

.action-btn:hover > .inactive-icon {
    color: var(--white);
}

.inactive{
    border: 1px solid var(--unnamed-color);
    background: var(--white);
}

.active{
    border: 1px solid var(--white);
    background: var(--primary) ;
    color: var(--white);

}