.item-wrapper{
    position: relative;
    background: var(--white);
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;
    padding: 40px 20px 30px 20px;
    margin: 10px;
}

.item-wrapper:hover #action-button{
    transform: translateY(-0);
    opacity: 1;
}

.item-img-wrapper{
    position: relative;
    width: auto;
}

.item-img{
    background: transparent no-repeat padding-box;
    width: 180px;
    height: 175px;
    position: relative;
    align-self: center;
}

.sub{
    width: 64px;
    height: 63px;
}

#context-menu {
    position: absolute;
    z-index: 10;
}

.border-item{
    position: relative;
    align-items: center;
    border-bottom: 3px solid #D90429;
    height: 65px;
    margin: 15px 0;
    width: 90%;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#action-button{
    transition: all 0.8ms ease-in-out;
    transform: translateY(-50%);
    opacity: 0;
}

.item-sub-img-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
}

.item-sub-img{
    position: relative;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    background: var(--white) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 1px #00000029;
    margin: 4px;
}

.inactive-price{
    font-size: 18px;
    font-weight: bold;
    color: var(--primary);
    position: absolute;
    top: 0;
    right: 0;
}

.item-foot{
    position: relative;
    margin: 0;
    width: 90%;
    align-self: center;
}

.item-name{
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: var(--product-title);
}

.item-name-sub{
    margin: 0;
    font-size: 18px;
    color: var(--product-short-desc);
}

.active-price{
    border: 1px solid #D90429;
    border-radius: 45px;
    padding: 7px;
    font-size: 18px;
    font-weight: bold;
    color: var(--primary);
}


/* Responsive styles */

@media screen and ( max-width: 1024px ) {
    #action-button{
        opacity: 1;
        transform: translateY(-0);
    }

    .item-sub-img-wrapper{
        display: none;
    }
}

@media screen and (max-width: 640px){
    
    .item-wrapper{
        width: calc(100vw * 0.34);
    }
    
    .item-img{
        width: calc(100vw * 0.25);
        height: calc(100vw * 0.25);
    }

    .border-item{
        width: 95%;
    }

    #item-foot{
        display: inline-block;
    }

    .active-price{
        border: none;
        margin: 0;
        padding: 0;
    }
}