.button{
    border: none;
    outline: none;
    height: 46px;
    width: 65%;
    background: var(--primary);
    color: var(--white);
    font-size: 18px;
    align-self: center;
    margin-bottom: 10px;
    border-radius: 20px;
}

.terms-services{
    text-align: center;
    font: normal 18px Futura;
    letter-spacing: 0px;
    color: var(--black-secondary);
}

.signin-link{
    color: var(--primary);
    font-size: 18px;
    margin: 4px 5px;
}

.signin-link:hover {
    cursor: pointer;
}

.reg-form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and ( max-width: 850px ) {
    .terms-services{
        font-size: 14.5px;
    }
    
}

@media screen and ( max-width: 700px ) {
    .terms-services{
        font-size: 12px;
    }
    
}

@media screen and ( max-width: 540px ) {
    form span{
        font-size: .6em;
    }
    
}