.logo{
    margin: 25px 0;
}

.signIn-signUp{
    justify-content: space-around;
}

@media screen and ( max-width: 1024px ) {
    #signIn-signUp{
        flex-direction: column;
    }
}