.bar-wrapper{
    height: 3.5rem;
    background: #FBFBFB 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 6px #0000001A;
    padding-left: 15px;
    padding-right: 15px;

}

.slash{
    font-size: 28px;
    color: #707070;
    margin: 8px;
}

.bar-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background: #00B723 0% 0% no-repeat padding-box;
    border: 1px solid #D8D8D8;
    border-radius: 60px;
    margin-right: 5px;
}

.secure-text{
    font-size: 20px;
    font-weight: normal;
    color: var(--unnamed-color);
}

.ship-text{
    font-size: 16px;
    font-weight: normal;
    color: var(--primary);
}

.ship-text:hover{
    cursor: pointer;
}