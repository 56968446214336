@tailwind base;
@tailwind components;
@tailwind utilities;

.wrapper {
    width: 100%;
    margin-left: 0;
    margin-bottom: 10px;
}

.input-wrapper {
    position: relative;
    border: 1px solid #e4e4e4;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    justify-content: flex-start;
    border-radius: 40px;
    height: 45px;
    padding-left: 15px;
    margin-bottom: .3rem;

}

.input-side-icon {
    background: var(--primary);
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-side-icon:hover {
    cursor: pointer;
}

.input {
    flex: 1;
    outline: none;
    border: none;
    height: 100%;
    font-size: 20px;
    color: var(--black-secondary);
}

.focused {
    border: 1px solid #D90429;
}

.error {
    border: 1px solid #F1584D;
}

.error-input {
    text-align: left;
    font-size: 16px;
    color: var(--danger);
    margin-top: .5rem;
    width: 50%;
    padding-left: 4px;
}