.arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  
  .dropdown {
    position: absolute;
    left: 100%;
    box-shadow: 0px 4px 6px #0000001A;
    margin: 0;
    padding: 0;
    width: 100%;
    top: 0;
    background: var(--white);
    /* list-style-type: none; */
    display: none;
  }
  
  .dropdown.show {
    display: block;
  }
  
  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: 0;
  }