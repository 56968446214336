@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "zilla-light";
  src: url("./assets/fonts/ZillaSlab-Regular.ttf");
}

@font-face {
  font-family: "outfit-bold";
  src: url("./assets/fonts/Outfit-Bold.ttf");
}

@font-face {
  font-family: "outfit-medium";
  src: url("./assets/fonts/Outfit-Medium.ttf");
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

h4,
h5,
h6,
p,
div,
main,
nav,
span,
a,
li,
em,
strong,
i,
td,
button,
b,
dt,
dd,
mark,
sub,
del,
sup,
small {
  font-family: zilla-light;
  font-size: 18px;
}

h1,
h2,
h3 {
  font-family: outfit-bold;
}

html {
  scrollbar-width: none;
}

body {
  background: #fff;
  margin: 0;
}

/* *::-webkit-scrollbar{
    display: none;
  } */
/* #9C27B0 */

@layer base {
  :root {
    --border: #e4e4e4;
    --unnamed-color-111111: #111111;
    --primary: #d90429;
    --white: #ffffff;
    --white-smoke: #f5f5f5;
    --515bde: #ff8e0a;
    --pages-bg: #f9f9f9;
    --header-color: #fdfdfd;
    --footer-top: #232323;
    --footer-bottom: #1a1a1a;
    --black: #000000;
    --black-secondary: #303030;
    --product-title: #111111;
    --product-short-desc: #707070;
    --inactive: #d8d8d8;
    --title-gray: #646464;
    --unnamed-color: #979797;
    --title-light-gray: #cecece;
    --danger: #f1584d;
    --contexify-menu-bgColor: rgba(40, 40, 40, 0.98);
    --contexify-separator-color: #4c4c4c;
    --contexify-item-color: #fff;
    --contexify-activeItem-color: #fff;
    --contexify-activeItem-bgColor: #d90429;
    --contexify-rightSlot-color: #6f6e77;
    --contexify-activeRightSlot-color: #fff;
    --contexify-arrow-color: #6f6e77;
    --contexify-activeArrow-color: #fff;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.row-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.row-flex-space {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.column-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.link {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
  padding: 0;
}

.auth-text {
  color: var(--product-short-desc);
  text-align: center;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--title-light-gray);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
