.container{
    flex: 1;
    height: 100vh;
}

.product-tab{
    display: grid;
    gap: 1rem;
    flex: 1;
    grid-template-columns: repeat(5, 1fr);

}

.filters-wrapper{
    margin-top: 20px;
    background: #F8F8FA 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 6px #0000001A;
    padding: 5px;
}

.filters{ 
    margin: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    width: 65px;
    height: 35px;
    cursor: default;
}

.filters:hover {
    border-top-right-radius: 15px;
    background: #FBEDFE 0% 0% no-repeat padding-box;
    border-bottom-left-radius: 15px;
}

.filters span{
    font: normal 14px Futura;
    color: #656565;
}

.active-filter{
    background: #FBEDFE 0% 0% no-repeat padding-box;
}

.inactive-filter{
    background: #F8F8FA 0% 0% no-repeat padding-box;
}

.filter-container{
    margin-top: 10px;
}

.category-filter{
    background: var(--white);
    box-shadow: 0px 4px 6px #0000001A;
    padding: 20px 15px 20px 10px;
    margin-right: 20px;
    margin-top: 10px;
    width: 15%;
    border-radius: 15px;
}

#filter-item{
    cursor: pointer;
    margin: 6px 4px;
}


#filter-item span{
    margin-left: 10px;
    font: 16px Futura;
    color: #656565;
}

@media screen and (max-width: 1900px) {
    .product-tab{
        grid-template-columns: repeat(4, 1fr);
    
    }
}

@media screen and (max-width: 1440px) {
    .category-filter{
        width: fit-content;
    }
    .product-tab{
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }
}


@media screen and (max-width: 950px) {
    .product-tab{
        grid-template-columns: repeat(2, 1fr);
    
    }
}

@media screen and (max-width: 680px) {
    #tab-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}