.card-wrapper{
    box-shadow: 4px 8px 12px #00000029;
    position: relative;
    margin: 0 auto;
    max-width: 585px;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 15px;
    align-items: center;
    align-self: center;
    background: var(--white);
    padding-bottom: 30px;
}

.card-header{
    width: 100%;
    background: var(--primary);
    position: relative;
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;

}

.card-title{
    color: var(--white);
    font-size: 35px;
    margin: 0;
    font-weight: bolder;
}

.card-icon{
    position: absolute;
    right: 20px;
    color: var(--white);
}

.card-icon:hover {
    cursor: pointer;
}

.card-content{
    width: 80%;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: relative;
}

@media screen and ( max-width: 550px ) {
    .card-title{
        font-size: 28px;
    }

    .card-icon{
        font-size: 28px;
    }

    .card-wrapper{
        width: 95%;
    }

    .card-content{
        width: 100%;
    }
}

@media screen and ( max-width: 480px ) {
    .card-title{
        font-size: 20px;
    }

    .card-icon{
        font-size: 28px;
    }
}

@media screen and ( max-width: 412px ) {
    .card-title{
        font-size: 17px;
    }

    .card-icon{
        font-size: 28px;
    }
}