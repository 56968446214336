.details-wrapper{
    width: 100%;
    align-items: flex-start;
    margin-top: 30px;
    box-sizing: content-box;
}

.sub-item-img{
    width: 120px;
    height: 130px;
}

#img-carousel{
    width: 100%;
    overflow: hidden;
}

.no-spinner {
    -moz-appearance: textfield;

    appearance: textfield;
}


.main-item-img{
    width: 100%;
    height: 75%;
}

.main-item-img img{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    pointer-events: none;
}

.sub-item-img img{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    pointer-events: none;
}



.gallery {
    display: grid;
    grid-template-columns: repeat(8, 10vh);
    grid-template-rows: repeat(8, 5vw);
    grid-gap: 10px;
}

.gallery-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item-1{
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
}

.item-2{
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 7;
}

.item-3{
    grid-column-start: 3;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 7;
}

.item-4{
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 7;
    grid-row-end: 9;
}

.item-5{
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 7;
    grid-row-end: 9;
}

.item-6{
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 7;
    grid-row-end: 9;
}


.item-id{
    color: var(--title-light-gray);
    font-weight: normal;
    font-size: 18px;
}

.side-wrapper{
    height: 35rem;
    width: 50%;
}

.img-grid{
    display: grid;
    grid-template-columns: 30% auto;
}

img{
    margin-top: 0;
    padding: 0;
}

.product-title{
    color: var(--black);
    font-size: 34px;
    font-weight: 500;
    text-align: left;
    margin: 0;
}

.product-price{
    color: var(--primary);
    font-weight: 500;
    margin: 0;
    text-align: right;
}

.product-delivery-price{
    color: #9b27b06b;
    font-size: 14px;
    font-weight: normal;
    text-align: right;
    width: 100%;
    margin-left: 10px;
}

.line-through{
    text-decoration: line-through;
    opacity: 0.45;
    color: rgb(156 163 175);
    padding-left: 5px;
    margin: 0px;
}

.delivery-text{
    display: none;
}

.foot-ship{
    width: 100%; 
    padding: 25px; 
    background-color: #F5F5F5;
    border-radius: 15px;
}

.delivery-inactive{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    align-items: center;
    background: #4542422F 0% 0% no-repeat padding-box;;
    justify-content: center;
    border: 1px solid transparent;
    display: flex;
    margin: 6px;
}

.delivery-active{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid lightgray;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 6px;
    box-shadow: 0px 5px 20px #2424240D;
}

.color-inactive{
    width: 45px;
    height: 45px;
    border: 1px solid #E6E6E6;
    align-items: center;
    justify-content: center;
    display: flex;
}

.color-active{
    width: 45px;
    height: 45px;
    border: 1px solid #D90429;
    align-items: center;
    justify-content: center;
    display: flex;
}

.color-holder{
    width: 25px;
    height: 25px;
    border: 1px solid #E6E6E6;

}

.size-inactive{
    width: 76px;
    height: 37px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D8D8D8;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.size-active{
    width: 76px;
    height: 37px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D90429;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
}

/* ProductQty */
.counter{
    height: 46px;
    width: 20%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D8D8D8;
    border-radius: 50px;
}

.fav-btn{
    align-items: center;
    justify-content: center;
    display: flex;
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: 50px;
    border: 1px solid #D8D8D8;
    margin: 0 8px;

}

.fav-btn:hover{
    cursor: pointer;
    border: 1px solid var(--white);
    background: var(--primary);
    color: var(--white);
}

#pdt-qty-mob{
    display: none;
}

/* border */
.border{
    border: 1px solid lightgray;
}

/* .add-to-cart */

.add-cart-wrapper{
    max-height: 40vh;
    background: #FFFFFF padding-box; 
    position: absolute;
    width: 90%;
}

.add-to-cart{
    width: 65%;
    height: 60%;
    padding-left: 10px;
    padding-top: 0;
}

.cart-item-title{
    font-size: 30px;
    font-weight: normal;
    color: #000;
    margin: 0;
}

.gray{
    color: gray;
    letter-spacing: 2px;
}

.cart-item-price{
    font-size: 30px;
    font-weight: normal;
    color: #000;
}

.cart-item-foot{ 
    position: relative; 
    bottom: 0; 
    height: 3rem; 
    color: white; 
    font-size: 20px; 
    font-weight: normal; 
    background: var(--primary) }

.cart-foot-text{
    font-size: 1.5em;
    font-weight: normal;
    font-family: Futura;
}


/* Responsive styles */

@media screen {
    
}

@media screen and ( max-width: 1150px ) {
   .spec-section{
    width: calc(100vw / 2.5);
   }

   .details-wrapper{
   }
}

@media screen and ( max-width: 1090px ) {
    #details-wrapper{
        flex-direction: column-reverse;
    }

    .spec-section{
        width: calc(100vw / 1.5);
       }

    .foot-ship{
        width: 90%;
    }
}


@media screen and ( max-width: 860px ) {
    .spec-section{
        width: 90vw;
       }
}

@media screen and ( max-width: 550px ) {

    #pdt-qty{
        display: none;
    }

    #pdt-qty-mob{
        display: block;
    }

    .spec-section{
        width: 90vw;
        height: fit-content;
    }

    .counter{
        width: 35%;
    }

    .btn-container{
        text-align: center;
    }
}


@media screen and ( max-width: 470px ) {
    #side-top{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        height: fit-content;
    }

    #color-wrap, #size-wrap{
        flex-wrap: wrap;
    }

    .side-wrapper{
        height: auto;
    }

    #side-top .item-id{
        font-size: 24px;
        font-weight: 550;
    }

    #delivery-wrapper{
        margin: 8px 0;
    }

    .delivery-text{
        display: block;
    }

    #ship-price{
        flex-direction: column;
        align-items: flex-start;
    }
}

