#app {
  height: 100%;
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.dots {
  background: transparent;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.home-hero-1 {
  background: url('../../assets/images/carousels/carousel_1.jpg') content-box center;
  background-size: contain;
  position: relative;
  object-fit: cover;

  background-repeat: no-repeat;
  height: 450px;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-hero-2 {
  background: url('../../assets/images/carousels/carousel_2.jpg') content-box center;
  background-size: contain;
  position: relative;
  background-repeat: no-repeat;
  object-fit: cover;
  height: 450px;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-hero-3 {
  background: url('../../assets/images/carousels/carousel_3.jpg') content-box center;
  background-size: contain;
  position: relative;
  object-fit: cover;

  background-repeat: no-repeat;
  height: 450px;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-hero-4 {
  background: url('../../assets/images/carousels/carousel_4.jpg') content-box center;
  background-size: contain;
  object-fit: cover;
  position: relative;
  background-repeat: no-repeat;
  height: 450px;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-hero-5 {
  background: url('../../assets/images/carousels/carousel_5.jpg') content-box center;
  background-size: contain;
  object-fit: cover;
  position: relative;
  background-repeat: no-repeat;
  height: 450px;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-hero-6 {
  background: url('../../assets/images/carousels/carousel_6.jpg') content-box center;
  background-size: contain;
  object-fit: cover;
  position: relative;
  background-repeat: no-repeat;
  height: 450px;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-hero-7 {
  background: url('../../assets/images/carousels/carousel_7.jpg') content-box center;
  background-size: contain;
  object-fit: cover;
  position: relative;
  background-repeat: no-repeat;
  height: 450px;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

/* 
.home-hero-8 {
  background: url('../../assets/images/women_dress_5.jpg') content-box center;
  background-size: contain;
  object-fit: cover;
  position: relative;
  background-repeat: no-repeat;
  height: 450px;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
} */

.dot-container {
  background: transparent;
}

.dot {
  background: transparent;
}

.container-cl {
  background: transparent;
}